import React, { useState, useContext, useMemo, useEffect, useCallback, createContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

const ADMIN_URL_REGEX = /^admin:(\/.*)$/;

const convertAdminLink = (url, shopOrigin) => {
  const [matched, path] = ADMIN_URL_REGEX.exec(url) || [];

  if (matched) {
    const shopHandle = shopOrigin?.replace(/^(?:https:)?(?:\/\/)?|\.myshopify\.com$/g, "");
    return window.shopify ? url.replace(/^admin:/, "shopify://admin") : `https://admin.shopify.com${shopHandle ? `/store/${shopHandle}` : ""}${path}`;
  }
  return url;
};

const useAppNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return useCallback(
    (to) => {
      const urlObject = new URL(to, window.location);
      const internalTarget = urlObject.pathname + urlObject.search + urlObject.hash;
      const currentPage = location.pathname + location.search + location.hash;
      const isDuplicateNavigation = currentPage === internalTarget;
      navigate(internalTarget, {
        replace: isDuplicateNavigation,
      });
    },
    [location.pathname, location.search, location.hash, navigate],
  );
};

function decorateUrl(url, shopOrigin, hostParam, urlToken) {
  const urlObject = new URL(url, window.location);
  if (window.shopify) {
    if (hostParam) {
      urlObject.searchParams.set("shop", shopOrigin);
      urlObject.searchParams.set("host", hostParam);
    }
    if (urlToken) {
      urlObject.searchParams.set("token", urlToken);
    }
  }
  return urlObject;
}

/* Begin AUTH */

const PROFILE_PATH = "/api/profile";
const AuthContext = createContext();

const getFetchProxy = (token) =>
  !token
    ? window.fetch
    : async (url, options = {}, ...rest) => {
        const sessionToken = token;
        const authorizationOverrides = sessionToken ? { Authorization: `Bearer ${sessionToken}` } : {};

        return window.fetch(
          url,
          {
            ...options,
            headers: {
              ...authorizationOverrides,
              ...options.headers,
            },
            credentials: "omit",
          },
          ...rest,
        );
      };

export function AuthProvider({ urlToken, embedded, children }) {
  const [profileIsKnown, setProfileIsKnown] = useState(false);
  const [profile, setProfile] = useState(null);
  const [profileError, setProfileError] = useState(null);
  const fetch = useMemo(() => getFetchProxy(urlToken), [urlToken]);

  const {
    apiKey,
    shop: shopOrigin,
    host,
  } = useMemo(() => {
    if (window.shopify) {
      return window.shopify.config;
    }

    const documentApiKey = process.env.REACT_APP_SHOPIFY_API_KEY;

    if (urlToken) {
      try {
        const { dest } = jwtDecode(urlToken);
        const shop = /[^/.]+\.myshopify.com/.exec(dest);

        return {
          apiKey: documentApiKey,
          shop,
          host: null,
        };
      } catch {
        // fall through
      }
    }

    return {
      apiKey: documentApiKey,
      shop: null,
      host: null,
    };
  }, [urlToken]);

  const isAuthenticated = !!(embedded || shopOrigin || urlToken);

  const invalidateProfile = () => {
    setProfileIsKnown(false);
  };

  // App Bridge will force a reload on the page
  const waitingForReload = isAuthenticated && embedded && window.self === window.top;

  useEffect(() => {
    if (!profileIsKnown && !waitingForReload && isAuthenticated) {
      fetch(PROFILE_PATH)
        .then((r) => r.json())
        .then((data) => {
          if (data.error) {
            setProfile(null);
            setProfileError(data);
          } else {
            setProfile(data);
            setProfileError(null);
          }
        })
        .finally(() => setProfileIsKnown(true));
    }
  }, [fetch, isAuthenticated, profileIsKnown, waitingForReload]);


  const context = useMemo(
    () => ({
      apiKey,
      shopOrigin,
      host,
      urlToken,
      isAuthenticated,
      fetch,
      profile,
      profileError,
      invalidateProfile,
    }),
    [
      apiKey,
      shopOrigin,
      host,
      urlToken,
      isAuthenticated,
      fetch,
      profile,
      profileError,
    ],
  );

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}

export const useShopOrigin = () => useContext(AuthContext).shopOrigin;
export const useHostParam = () => useContext(AuthContext).host;
export const useIsAuthenticated = () => useContext(AuthContext).isAuthenticated;
export const useInternalUseOnlyUrlTokenDoNotExport = () => useContext(AuthContext).urlToken;
export const useFetch = () => useContext(AuthContext).fetch;
export const useApiKey = () => useContext(AuthContext).apiKey;
export const useFetchSessionToken = () => {
  const { urlToken } = useContext(AuthContext);
  return async () => urlToken || window.shopify.idToken();
};

export const useProfile = () => {
  const auth = useContext(AuthContext);
  return [auth.profile, auth.profileError, auth.invalidateProfile];
};

export const useRedirect = () => {
  const shopOrigin = useShopOrigin();
  const appNavigate = useAppNavigate();

  return useCallback(
    (to) => {
      const urlObject = new URL(convertAdminLink(to, shopOrigin), window.location);

      const inApp = urlObject.origin === window.location.origin;

      if (inApp) {
        appNavigate(urlObject.toString());
      } else {
        window.open(urlObject.toString(), "_top");
      }
    },
    [appNavigate, shopOrigin],
  );
};

export function Link({ children: linkChildren, url: providedUrl, to: providedTo, ...rest }) {
  const shopOrigin = useShopOrigin();
  const hostParam = useHostParam();
  const urlToken = useInternalUseOnlyUrlTokenDoNotExport();
  const appNavigate = useAppNavigate();

  const url = providedUrl || providedTo;

  const [newURL, inApp] = useMemo(() => {
    const decoratedUrl = decorateUrl(convertAdminLink(url, shopOrigin), shopOrigin, hostParam, urlToken);
    return [decoratedUrl, decoratedUrl.origin === window.location.origin];
  }, [hostParam, shopOrigin, url, urlToken]);

  const internalOnClick = useCallback(
    (e) => {
      e.preventDefault();
      appNavigate(url);
    },
    [appNavigate, url],
  );

  return (
    <a
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      href={newURL.toString()}
      onClick={inApp ? internalOnClick : undefined}
      target={inApp ? "_self" : "_top"}
      rel="noreferrer"
    >
      {linkChildren}
    </a>
  );
}

/* end AUTH */

export default {};
