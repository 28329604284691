import React, { useState, useEffect, useCallback, useRef } from "react";
import { useFetch, Link } from "../../utils";
import StyleIsolation from "../../components/StyleIsolation";
import LegacyContainer from "../../components/LegacyContainer";
import LegacyMessageContext from "../../components/LegacyMessageContext";
import Sorter from "../components/Sorter";
import CreatePagination from "../components/CreatePagination";

function POSWishlists() {
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const fetch = useFetch();

  const [email, setEmail] = useState("");
  const [items, setItems] = useState(50);
  const [page, setPage] = useState(1);
  const [dir, setDir] = useState(null);
  const [order, setOrder] = useState(null);

  const updateDir = useCallback((newDir) => {
    setDir(newDir);
    setLoaded(false);
  }, []);

  useEffect(() => {
    if (!loaded) {
      const url = new URL("/reggie/wishlists", window.location);
      if (email) {
        url.searchParams.append("email", email);
      }
      url.searchParams.append("items", items || 50);
      url.searchParams.append("page", page || 1);
      if (dir && order) {
        url.searchParams.append("dir", dir);
        url.searchParams.append("order", order);
      }
      fetch(url, { headers: { Accept: "application/json" } })
        .then((r) => r.json())
        .then((nextData) => {
          setData(nextData);
          setLoaded(true);
        })
        .catch((ex) => {
          console.error("Fetching Dashboard Data", ex);
        });
    }
  }, [fetch, loaded, email, items, page, dir, order]);

  const emailChange = useCallback((e) => setEmail(e.target.value), []);
  const itemsChange = useCallback(
    (e) => {
      setItems(e.target.value);
      if (Number(items) !== Number(e.target.value)) {
        setPage(1);
      }
      setLoaded(false);
    },
    [items]
  );

  const submit = useCallback((e) => {
    e.preventDefault();
    setPage(1);
    setLoaded(false);
  }, []);

  const clear = useCallback((e) => {
    e.preventDefault();
    setPage(1);
    setEmail("");
    setLoaded(false);
  }, []);

  const updatePage = useCallback((pageNumber) => {
    setPage(pageNumber);
    setLoaded(false);
  }, []);

  const [createWishlistVisible, setCreateWishlistVisible] = useState(false);

  const toggleCreateWishlistVisible = useCallback(
    () => setCreateWishlistVisible(!createWishlistVisible),
    [createWishlistVisible]
  );

  const createWishlistRef = useRef(null);

  const createWishlist = useCallback(
    (e) => {
      e.preventDefault();

      const formData = new URLSearchParams(new FormData(e.target));
      fetch("/reggie/wishlists", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      })
        .then((r) => r.text())
        .then((text) => {
          const errorMatch =
            /displayError\("((?:[^\\"]|\\["bfnrtu])*)"\);/.exec(text);
          const successMatch =
            /displaySuccess\("((?:[^\\"]|\\["bfnrtu])*)"\);/.exec(text);
          const warningMatch =
            /displayWarning\("((?:[^\\"]|\\["bfnrtu])*)"\);/.exec(text);

          if (errorMatch)
            console.error("[GiftReggie] Got a response error", errorMatch[1]);
          if (successMatch)
            console.log("[GiftReggie] Got a success error", successMatch[1]);
          if (warningMatch)
            console.warn("[GiftReggie] Got a warning error", warningMatch[1]);

          setLoaded(false);
          setCreateWishlistVisible(false);
          createWishlistRef.current.reset();
        })
        //  .then((r) => r.json())
        //  .then((nextData) => {
        //    setData(nextData);
        //  })
        .catch((ex) => {
          console.error("Fetching Dashboard Data after Submit", ex);
        });
    },
    [fetch]
  );

  const clearCreateWishlist = useCallback(() => {
    createWishlistRef.current.reset();
    setCreateWishlistVisible(false);
  }, []);

  return data ? (
    <StyleIsolation>
      <LegacyContainer>
        <div className="row-fluid">
          <div className="span12">
          <Link to='/dashboard'>
          ← Back
          </Link>

            <h3>Wishlists</h3>
            <hr />
          </div>
        </div>

        <div className="row-fluid">
          <div className="span12">
            <form onSubmit={submit}>
              Search by name or email{" "}
              <input
                id="wishlist-email"
                type="text"
                style={{ margin: 0, width: "128px" }}
                placeholder="anything"
                value={email}
                onChange={emailChange}
              />{" "}
              <button type="submit" className="btn" id="filter-submit">
                Search
              </button>{" "}
              <button
                type="button"
                className="btn"
                id="filter-clear"
                onClick={clear}
              >
                Clear
              </button>
            </form>
          </div>
        </div>

        <div className="row-fluid" style={{ marginTop: "12px" }}>
          <div className="span12">
            <div hidden={createWishlistVisible}>
              <button
                type="button"
                onClick={toggleCreateWishlistVisible}
                className="btn btn-reggie"
                style={{marginBottom: "10px"}}
              >
                New Wishlist
              </button>
            </div>
            <div
              className="add-shown"
              style={{ marginBottom: "24px" }}
              hidden={!createWishlistVisible}
            >
              <form
                method="post"
                onSubmit={createWishlist}
                ref={createWishlistRef}
              >
                <input type="hidden" name="action" value="create" />
                <div className="row-fluid">
                  <div className="span12" style={{ textAlign: "center" }}>
                    <h3>Create Wishlist</h3>
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span12">
                    <p>
                      Please note that creating a list for a customer that does
                      not exist in your shop will send out an invitation for
                      that customer to create an account.
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row-fluid">
                  <div className="span4">Email</div>
                  <div className="span8">
                    <input
                      type="email"
                      name="wishlist-email"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span4">First Name</div>
                  <div className="span8">
                    <input
                      type="text"
                      placeholder="Only required if the user doesn't exist already in your store."
                      name="wishlist-first-name"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span4">Last Name</div>
                  <div className="span8">
                    <input
                      placeholder="Only required if the user doesn't exist already in your store."
                      type="text"
                      name="wishlist-last-name"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="row-fluid">
                  <div className="span12">
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={clearCreateWishlist}
                    >
                      Discard Changes
                    </button>{" "}
                    <button
                      type="submit"
                      id="add-registry-button"
                      className="btn btn-success"
                    >
                      Add Wishlist
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <table className="table table-striped registry-table">
              <thead>
                <tr>
                  <th id="heading-id">
                    ID NUMBER
                    <Sorter
                      forOrder="id"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-name">
                    Name
                    <Sorter
                      forOrder="email"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-created">
                    DATE CREATED
                    <Sorter
                      forOrder="created"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.WISHLISTS?.length ? (
                  data.WISHLISTS.map((innerData) => (
                    <tr key={innerData.ID}>
                      <td>
                        <Link to={`/pos_wishlists/${innerData.ID}`}>
                          #{innerData.ID}
                        </Link>
                      </td>
                      <td>{`${innerData.OWNER_FIRST} ${innerData.OWNER_LAST} (${innerData.OWNER_EMAIL})`}</td>
                      <td>{innerData.CREATED}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9">
                      There are currently no wishlists present matching your
                      query.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div id="registry-pagination" style={{ textAlign: "center" }}>
              <CreatePagination
                maxPages={data.MAX_PAGES}
                page={page}
                setPage={updatePage}
              />
            </div>
          </div>
        </div>
        <div className="row-fluid">
          <div className="span6" style={{ textAlign: "right" }}>
            Per Page{" "}
            <select
              id="page-selector"
              style={{ width: "auto" }}
              value={items}
              onChange={itemsChange}
            >
              <option>50</option>
              <option>100</option>
              <option>200</option>
            </select>
          </div>
        </div>
      </LegacyContainer>
    </StyleIsolation>
  ) : null;
}

export default () => (
  <LegacyMessageContext>
    <POSWishlists />
  </LegacyMessageContext>
);
