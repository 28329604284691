/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Guide.scss";
import { Card, InlineStack, Spinner, Badge, Page, Box, Button, Text } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { useFetch, useProfile } from "../utils";

function POSPreferences() {
  const [profile, , invalidateProfile] = useProfile();
  const [waitingForRequest, setWaitingForRequest] = useState(false);
  const authFetch = useFetch();

  const toggleNavigation = useCallback(
    async () => {
        setWaitingForRequest(true)
      await authFetch("/api/profile/pos_navigation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {invalidateProfile(); setWaitingForRequest(false)})
      .catch((ex) => console.error(ex));
    },
    [authFetch, invalidateProfile]
  );

  return (
    profile && (
      <Page fullWidth>
        <Card>
          <Text as="h2" variant="headingSm">
            POS Navigation
          </Text>
          <Box paddingBlockStart="200">
            <InlineStack gap="200">
              {waitingForRequest ? <Spinner/> : <Button size="large" onClick={toggleNavigation}>{profile.shop.pos_navigation ? "Hide Navigation in POS" : "Show Navigation in POS"}</Button>}
              <Badge tone={profile.shop.pos_navigation ? "success" : "warning"}>{profile.shop.pos_navigation ? "POS Navigation Visible" : "POS Navigation Hidden"}</Badge>
            </InlineStack>
            <Box paddingBlockStart="200">
              <Text>If allowed, POS users will be able to browse the full Gift Reggie admin panel. Otherwise, they will just be able to use the POS specific actions</Text>
            </Box>
          </Box>
        </Card>
      </Page>
    )
  );
}

export default POSPreferences;
