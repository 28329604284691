import React, { useEffect } from "react";

import { useDisplayRoutes } from "../RouteManager";
import { useProfile, useRedirect } from "../utils";

function AppBridgeNavigation() {
  const displayRoutes = useDisplayRoutes();
  const redirect = useRedirect();
  const [profile] = useProfile();
  const posShowNavigation = window?.shopify?.environment?.pos ? profile?.shop?.pos_navigation : true; // Only show Dashboard for navigation on POS if setting is enabled
  useEffect(() => {
    document.title = "";
  }, []);

  return posShowNavigation ? (
    <ui-title-bar>
      {displayRoutes.map(({ name, path, items = [] }) =>
        items.length > 0 ? (
          <section label={name} key={name}>
            {items.map(({ name: subName, path: subPath }) => (
              <button
                type="button"
                onClick={() => redirect(subPath)}
                key={subPath}
              >
                {subName}
              </button>
            ))}
          </section>
        ) : (
          <button type="button" onClick={() => redirect(path)} key={name}>
            {name}
          </button>
        )
      )}
    </ui-title-bar>
  ) :
  <ui-title-bar>
  {displayRoutes.filter(r => r.name === "Dashboard").map(({ name, path, items = [] }) =>
    items.length > 0 ? (
      <section label={name} key={name}>
        {items.map(({ name: subName, path: subPath }) => (
          <button
            type="button"
            onClick={() => redirect(subPath)}
            key={subPath}
          >
            {subName}
          </button>
        ))}
      </section>
    ) : (
      <button type="button" onClick={() => redirect(path)} key={name}>
        {name}
      </button>
    )
  )}
</ui-title-bar>

  ;
}

export default AppBridgeNavigation;
