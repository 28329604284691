/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Page, EmptyState, InlineStack, ResourceList, ResourceItem, Text, ChoiceList, Filters, Badge, BlockStack } from "@shopify/polaris";

import { useGiftReggieFetch } from "../../components/LegacyContainer";
import LegacyMessageContext from "../../components/LegacyMessageContext";

function SearchLists() {
  const BASE_URL = "/reggie/registries";

  const [data, setData] = useState(null);
  const fetch = useGiftReggieFetch();
  const location = useLocation();
  const navigate = useNavigate();
  const usp = new URLSearchParams(location.search);
  const [loaded, setLoaded] = useState(false);

  const defaultStatus = "open";
  const defaultSearchType = "search";
  const [listStatus, setListStatus] = useState(undefined);
  const [queryValue, setQueryValue] = useState(() => usp.get("query"));
  const [page, setPage] = useState(() => Number(usp.get("page")) || 1);
  const [status, setStatus] = useState(() => usp.get("status") || defaultStatus);
  const [query, setQuery] = useState(() => usp.get("query"));
  const [sortSelected, setSortSelected] = useState(() => usp.get("order"));
  const [dir, setDir] = useState(() => usp.get("dir"));

  const inputQuery = usp.get("query");
  const startDate = usp.get("start-date");
  const endDate = usp.get("end-date");
  const items = usp.get("items") || 50;
  const searchType = usp.get("search-type") || defaultSearchType;

  const urlWithParameters = useMemo(() => {
    const u = new URL(BASE_URL, window.location);
    if (startDate) {
      u.searchParams.set("start-date", startDate);
    }
    if (endDate) {
      u.searchParams.set("end-date", endDate);
    }
    if (items && String(items) !== "50") {
      u.searchParams.set("items", items);
    }
    if (page && String(page) !== "1") {
      u.searchParams.set("page", page);
    }
    if (status) {
      u.searchParams.set("status", status);
    }
    if (query) {
      u.searchParams.set("query", query);
    }
    if (searchType) {
      u.searchParams.set("search-type", searchType);
    }
    if (sortSelected) {
      u.searchParams.set("order", sortSelected);
    }
    if (dir) {
      u.searchParams.set("dir", dir);
    }
    return u;
  }, [dir, endDate, items, query, searchType, sortSelected, page, startDate, status]);

  const sortOptions = [
    { label: "Name", value: "name" },
    { label: "ID", value: "id" },
    { label: "Email", value: "email" },
    { label: "Event Date", value: "event" },
    { label: "Created Date", value: "created" },
  ];

  // update propagate url changes to window
  useEffect(() => {
    navigate(urlWithParameters.search, { replace: true });
  }, [navigate, urlWithParameters]);

  useEffect(() => {
    if (!loaded) {
      fetch(urlWithParameters, {
        headers: { Accept: "application/json" },
      })
        .then((nextData) => {
          setData(nextData);
        })
        .catch((ex) => {
          console.error("Fetching Registries list Data", ex);
        })
        .finally(() => {
          setLoaded(true);
        });
    }
  }, [fetch, loaded, urlWithParameters]);

  const updatePage = useCallback((pageNumber) => {
    setPage(pageNumber);
    setLoaded(false);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setQuery(inputQuery);
      updatePage(1);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [inputQuery, updatePage]);

  const updateSort = useCallback(
    (newOrder, newDir) => {
      setSortSelected(newOrder);
      setDir(newDir);
      updatePage(1);
    },
    [updatePage],
  );

  const renderItem = (list) => {
    const { ID, NAME, CREATED, STATUS, EVENT, REGISTRANT, COREGISTRANT, EMAIL, ITEMS, TYPE } = list;
    return (
      <ResourceItem
        id={ID}
        url={`/modify/${ID}`}
        accessibilityLabel={`List ${NAME}`}
        media={
          <BlockStack gap="500">
            <Badge tone={STATUS === "Open" ? "success" : "critical"}>{STATUS}</Badge>
            {TYPE && TYPE !== "null" && <Badge>{TYPE}</Badge>}
            <Badge>
              {ITEMS} {ITEMS === 1 ? "Product " : "Products"}
            </Badge>
          </BlockStack>
        }
        persistActions
      >
        <InlineStack align="flex-start" wrap={false}>
          <Text variant="bodyMd" fontWeight="bold" as="h3">
            {NAME}
          </Text>
          <Text tone="subdued"> #{ID}</Text>
        </InlineStack>
        <InlineStack align="space-between" wrap={false}>
          <div>
            <Text tone="subdued">Registrant:{" "}</Text><Text> {REGISTRANT}</Text>
            <Text tone="subdued">Email:{" "}</Text><Text>{EMAIL}</Text>
          </div>
          <div>
          {COREGISTRANT ?  <><Text tone="subdued">Co-Registrant:{" "}</Text><Text> {COREGISTRANT}</Text></> : <></>}
          </div>
          <div style={{ textAlign: "left", width: "250px" }}>
            <Text tone="subdued">Created: {CREATED}</Text>
            <Text tone="subdued">Event Date: {EVENT}</Text>
          </div>
        </InlineStack>
      </ResourceItem>
    );
  };

  const resourceName = {
    singular: "list",
    plural: "lists",
  };

  const emptyStateMarkup =
    !items.length && !queryValue ? (
      <EmptyState
        heading="You have no open lists"
        action={{ content: "Create List", url: "/create_registry" }}
        image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
      >
        <p>You can create a list here to get started</p>
      </EmptyState>
    ) : undefined;

  // ##################################

  const handleListStatusChange = useCallback((value) => {
    setListStatus(value);
    setStatus(value);
    updatePage(1);
  }, [updatePage]);

  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value);
    setQuery(value);
    updatePage(1);
  }, [updatePage]);
  const handleListStatusRemove = useCallback(() => {
    setListStatus(undefined);
    setStatus(defaultStatus);
    updatePage(1);
  }, [updatePage]);

  const handleQueryValueRemove = useCallback(() => {
    setQueryValue(undefined);
    setQuery(undefined);
    updatePage(1);
  }, [updatePage]);
  const handleFiltersClearAll = useCallback(() => {
    handleListStatusRemove();
    handleQueryValueRemove();
  }, [handleListStatusRemove, handleQueryValueRemove]);

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const filters = [
    {
      key: "listStatus",
      label: "Status",
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={[
            { label: "Open", value: "open" },
            { label: "All", value: "all" },
            { label: "Closed", value: "closed" },
          ]}
          selected={listStatus || []}
          onChange={handleListStatusChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(listStatus)) {
    const key = "listStatus";
    appliedFilters.push({
      key,
      label: listStatus,
      onRemove: handleListStatusRemove,
    });
  }
  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={handleFiltersQueryChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleFiltersClearAll}
    />
  );

  // ###################################

  return data ? (
    <Page title="Lists" backAction={{ content: "Dashboard", url: "/dashboard" }}>
      <ResourceList
        emptyState={emptyStateMarkup}
        resourceName={resourceName}
        items={data.REGISTRIES}
        renderItem={renderItem}
        sortValue={sortSelected}
        sortOptions={sortOptions}
        pagination={{
          hasNext: data.MAX_PAGES > page,
          hasPrevious: page > 1,
          onNext: () => {
            updatePage(page + 1);
          },
          onPrevious: () => {
            updatePage(page - 1);
          },
        }}
        onSortChange={(selected) => {
          updateSort(selected, "desc");
        }}
        filterControl={filterControl}
      />
    </Page>
  ) : null;
}

export default () => (
  <LegacyMessageContext>
    <SearchLists />
  </LegacyMessageContext>
);
