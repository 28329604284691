import React, { useState, useCallback, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Page, Card, InlineStack, Badge, Button, Modal, BlockStack, Text, TextField } from "@shopify/polaris";
import { DateTime } from "luxon";
import { BarcodeIcon, CartUpIcon } from "@shopify/polaris-icons";

import LegacyMessageContext from "../../components/LegacyMessageContext";
import CreatePagination from "../components/CreatePagination";
import Sorter from "../components/Sorter";
import { useFetch } from "../../utils";

function ModifyList() {
  const [data, setData] = useState(null);
  const [loadedId, setLoadedId] = useState(false);
  const { id } = useParams();
  const [showPageModal, setShowPageModal] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);

  const navigate = useNavigate();

  const fetch = useFetch();

  const [items, setItems] = useState(100);
  const [page, setPage] = useState(1);
  const [dir, setDir] = useState(null);
  const [order, setOrder] = useState(null);
  const [pendingAdditions, setPendingAdditions] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState([]);

  const [cartModalQuantity, setCartModalQuantity] = useState(1);
  const [cartModalShowWarning, setCartModalShowWarning] = useState(false);
  const [cartModalVariantId, setCartModalVariantId] = useState(null);
  const [cartModalVariantTitle, setCartModalVariantTitle] = useState(null);
  const [cartModalNeeds, setCartModalNeeds] = useState(null);
  const [cartModalWants, setCartModalWants] = useState(null);
  const [cartModalHas, setCartModalHas] = useState(null);

  const updatePage = useCallback((pageNumber) => {
    setPage(pageNumber);
    setLoadedId(false);
  }, []);

  const updateItems = useCallback(
    (e) => {
      setItems(Number(e.target.value));
      updatePage(1);
    },
    [updatePage],
  );

  const updateDir = useCallback((newDir) => {
    setDir(newDir);
    setLoadedId(false);
  }, []);

  useEffect(() => {
    if (id !== loadedId) {
      const url = new URL(`/reggie/registries/${id}`, window.location);
      url.searchParams.append("items", items);
      url.searchParams.append("page", page || 1);
      if (dir && order) {
        url.searchParams.append("dir", dir);
        url.searchParams.append("order", order);
      }

      fetch(url, {
        headers: { Accept: "application/json" },
      })
        .then((r) => r.json())
        .then((nextData) => {
          setData(nextData);
          setLoadedId(id);
        })
        .catch((ex) => {
          console.error("Fetching Data", ex);
        });
    }
  }, [fetch, loadedId, id, page, dir, order, items]);

  const submit = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new FormData(document.querySelector("form"));

      if(unsavedChanges.some(change => change.type === "addFromCart")){
        window.shopify.pos.cart.clear()
      }
      // the button that was pressed needs to be added to the form
      const submitButton = e.nativeEvent.submitter;
      if (submitButton && submitButton.name) {
        if (submitButton.name === "action") {
          formData.delete("action");
        }
        formData.append(submitButton.name, submitButton.value || "");
      }

      // if the button that was pressed was the customer delete button, add the customer id
      if (submitButton && submitButton.name === "action" && submitButton.value === "delete-customer") {
        formData.append("id", submitButton.closest("tr").dataset.id);
      }

      const dateFromInputElement = formData.get("event-date");
      try {
        const parsedDate = DateTime.fromISO(dateFromInputElement);
        const perlFriendlyDate = parsedDate.invalid
          ? data?.EVENT_DATE || "" // fall back onto the original passed in data
          : parsedDate.toFormat("MM/dd/yyyy");
        formData.set("event-date", perlFriendlyDate);
      } catch (ex) {
        console.log(`[GiftReggie] converting "${dateFromInputElement}" didn't go down so well`, ex);
      }

      setData(null);
      setPendingAdditions([]);
      setUnsavedChanges([])
      fetch(`/reggie/registries/${id}`, {
        method: "POST",
        body: formData,
      }).finally(() => {
        setLoadedId(false);
      });
    },
    [data?.EVENT_DATE, fetch, id, unsavedChanges],
  );

  const barcodeQuery = (barcode) => `query {
     productVariants(first: 1, query: "barcode:${barcode}") {
       edges {
         node {
           id,
           product{
             id,
             title,
             handle
           }
           title,
           sku,
           image{
             url,
             originalSrc,
             id,
             transformedSrc
           }
         }
       }
     }
   }`;

  const gqlQuery = useCallback(
    (query, variables) =>
      fetch("/api/shopify/2024-01/graphql.json", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ query, variables }),
      })
        .then((r) => r.json())
        .then((response) => response.data),
    [fetch],
  );

  const useScanner = useCallback(async () => {
    try {
      const scanner = await window.shopify.scanner.capture();
      if (scanner) {
        const variantFromScan = await gqlQuery(barcodeQuery(scanner.data));
        const variant = variantFromScan.productVariants.edges[0].node;
        const { product, image } = variant;
        const newLine = {
          productId: product.id.replace("gid://shopify/Product/",""),
          variantId: variant.id.replace("gid://shopify/ProductVariant/",""),
          title: `${product.title} - ${variant.title}`,
          handle: product.handle,
          sku: variant.sku,
          image: image?.url ? image.url : "",
          wants: 1
        };
        setPendingAdditions([...pendingAdditions, newLine]);
        setUnsavedChanges([...unsavedChanges, {type: "pendingAddition", target: newLine.variantId}]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [gqlQuery, pendingAdditions, unsavedChanges]);

  const addRegistryLineNumber = useCallback(async () => {
    if (window.shopify) {
      const selection = await window.shopify.resourcePicker({
        type: "product",
        multiple: true,
      });

      if (!selection) {
        return;
      }

      const newLines = selection.flatMap((product) =>
        product.variants.map((variant) => ({
          productId: String(product.id).replace(/^.*?\b(?=\d+$)/, ""),
          variantId: String(variant.id).replace(/^.*?\b(?=\d+$)/, ""),
          title: `${product.title} - ${variant.title}`,
          handle: product.handle,
          sku: variant.sku,
          image: variant.image?.originalSrc ?? product.images[0]?.originalSrc,
          wants: 1
          // price: variant.price, // TODO: format this
        })),
      );
      setPendingAdditions([...pendingAdditions, ...newLines]);
      setUnsavedChanges([...unsavedChanges, ...newLines.map((line) => ({
        type: "pendingAddition",
        target: line.variantId,
      }))]);
    }
  }, [pendingAdditions, unsavedChanges]);

  const addCart = useCallback(async () => {
    const cartObject = await window.shopify.pos.cart.fetch().catch((ex) => {console.error(ex); window.shopify.toast.show("Error fetching cart", { isError: true });});
    if(cartObject){
      const lineItems = cartObject.lineItems.map((lineItem) => ({
        productId: String(lineItem.productId).replace(/^.*?\b(?=\d+$)/, ""),
        variantId: String(lineItem.variantId).replace(/^.*?\b(?=\d+$)/, ""),
        title: `${lineItem.title} - ${lineItem.variantTitle}`,
        handle: null,
        sku: lineItem.sku,
        image: null,
        wants: lineItem.quantity,
      }));
      setPendingAdditions([...pendingAdditions, ...lineItems]);
      setUnsavedChanges([...unsavedChanges, ...lineItems.map((line) => ({
        type: "addFromCart",
        target: line.variantId,
      }))]);
    }
  }, [pendingAdditions, unsavedChanges]);


  const barcodeButton =
    window.shopify && window.shopify.scanner ? (
      <Button icon={BarcodeIcon} onClick={useScanner}>
        Scan Barcode
      </Button>
    ) : null;

  const addToCart = useCallback(
    async (variantId, variantTitle, quantity) => {
      try {
        await window.shopify.pos.cart.addLineItem(variantId, quantity);
        const cartObject = await window.shopify.pos.cart.fetch();
        const idx = cartObject.lineItems.findIndex((item) => item.variantId === variantId);
        await window.shopify.pos.cart.addLineItemProperties(idx, { registry_id: loadedId });

        window.shopify.toast.show(`Added ${quantity}x ${variantTitle} to cart`);
      } catch (e) {
        window.shopify.toast.show("Could not add Product", { isError: true });
      }
    },
    [loadedId],
  );

  const attemptAddToCart = useCallback((variantId, variantTitle, wants, has, needs) => {
    if (needs === 1) {
      addToCart(variantId, variantTitle, 1);
    } else {
      setCartModalVariantId(variantId);
      setCartModalVariantTitle(variantTitle);
      setCartModalWants(wants);
      setCartModalHas(has);
      setCartModalNeeds(needs);
      setShowCartModal(true);
      setCartModalQuantity(needs >= 1 ? needs : 1);
      setCartModalShowWarning(needs < 1);
    }
  }, [addToCart]);

  const cartButton =
    window.shopify && window.shopify.environment.pos ? (
      <Button icon={CartUpIcon} onClick={addCart}>
        Add from Cart
      </Button>
    ) : null;
    
  const deleteVariant = useCallback((e) => {
    e.target
      .closest("tr")
      .querySelectorAll(".item-wants,.item-has")
      .forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.value = 0;
        setUnsavedChanges(prevChanges => [
          ...prevChanges,
          { type: "input", target: element.name }
        ]);
        
      });
  }, []);

  const checkUnsaved = useCallback((target, initialValue, newValue) => {
    if(newValue && newValue !== "")
      if(initialValue !== parseInt(newValue, 10))
        setUnsavedChanges([...unsavedChanges.filter((change) => change.target !== target), {type: "input", target}]);
      else
        setUnsavedChanges([...unsavedChanges.filter((change) => change.target !== target)]);
  }, [unsavedChanges]);

  const clearChanges = useCallback(() => {
    setUnsavedChanges([]);
    window.location.reload();
  },[]);

  const handleBackAction = useCallback(() => {
    if (unsavedChanges.length > 0) {
      setShowPageModal(true);
    } else {
      navigate("/search"); 
    }
  }, [unsavedChanges, navigate]);

  const handleConfirmLeave = () => {
    setShowPageModal(false);
    navigate("/search");
  };

  const clearCartModal = () => {
    setCartModalVariantId(null);
    setCartModalVariantTitle("");
    setCartModalNeeds(0);
    setCartModalWants(0);
    setCartModalHas(0);
    setCartModalQuantity(1);
    setCartModalShowWarning(false);
    setShowCartModal(false);
  };

  const cartModalHandleQuantityChange = useCallback((value) => {
    const numValue = Math.max(1, Number(value));
    setCartModalQuantity(numValue);
    setCartModalShowWarning(numValue > cartModalNeeds);
  }, [cartModalNeeds]);

  const cartModalIncreaseQuantity = () => cartModalHandleQuantityChange(cartModalQuantity + 1);
  const cartModalDecreaseQuantity = () => cartModalHandleQuantityChange(cartModalQuantity - 1);

  const cartModalHandleSubmit = () => {
    addToCart(cartModalVariantId, cartModalVariantTitle, cartModalQuantity);
    clearCartModal();
  };

  return data ? (
    <>
    <Page
      title={data.NAME}
      backAction={{ content: "Search", onAction: handleBackAction }}
      subtitle={`Created ${data.CREATED}`}
      secondaryActions={[
        {
          content: 'Clear Changes',
          accessibilityLabel: 'Clear Changes',
          onAction: clearChanges,
        },
        {
          content: 'Save Changes',
          accessibilityLabel: 'Save Changes',
          onAction: submit,
        },
      ]}
      titleMetadata={
        <InlineStack gap="200">
          {data.EVENT_TYPE && data.EVENT_TYPE !== "null" && <Badge>{data.EVENT_TYPE}</Badge>}
          <Badge tone={data.CLOSED ? "failure" : "success"}>{data.CLOSED ? "Closed" : "Open"}</Badge>
          <Badge tone={unsavedChanges.length > 0  ? "warning" : "success"}>{unsavedChanges.length > 0  ? "Unsaved Changes" : "Saved"}</Badge>
        </InlineStack>
      }
      fullWidth
    >
      <Card title="Registry Listing">
        <form id="registry-update-form" onSubmit={submit}>
          <input type="hidden" name="updated" value={data.UPDATED} />
          <input name="registry-title" type="hidden" defaultValue={data.NAME}/>
          <input name="registry-description" type="hidden" defaultValue={data.DESCRIPTION} />
          <input name="event-type" type="hidden" defaultValue={data.EVENT_TYPE} />
          <input name="event-date" type="hidden" defaultValue={DateTime.fromFormat(data.EVENT_DATE, "MM/dd/yyyy").toISODate() || ""} />
          <input name="about-type" type="hidden" defaultValue={data.ABOUT_TYPE} />
          {data.EMAIL_MANAGERS ? <input type="hidden" name="email-managers" value="on" /> : null}
          <input name="extra" type="hidden" defaultValue={data.EXTRA} />
          <input name="registrant-title" type="hidden" maxLength="16" defaultValue={data.REGISTRANT_TITLE} />
          <input name="registrant-first" type="hidden" defaultValue={data.REGISTRANT_FIRST} />
          <input name="registrant-last" type="hidden" defaultValue={data.REGISTRANT_LAST} />
          <input name="coregistrant-title" type="hidden" maxLength="16" defaultValue={data.COREGISTRANT_TITLE} />
          <input name="coregistrant-first" type="hidden" defaultValue={data.COREGISTRANT_FIRST} />
          <input name="coregistrant-last" type="hidden" defaultValue={data.COREGISTRANT_LAST} />
          <input name="contact-address" type="hidden" defaultValue={data.CONTACT_ADDRESS} />
          <input name="contact-city" type="hidden" defaultValue={data.CONTACT_CITY} />
          <input name="contact-province" type="hidden" defaultValue={data.CONTACT_PROVINCE} />
          <input name="contact-country" type="hidden" defaultValue={data.CONTACT_COUNTRY} />
          <input name="contact-postal" type="hidden" defaultValue={data.CONTACT_POSTAL} />
          <input name="contact-daytime" type="hidden" defaultValue={data.CONTACT_DAYTIME} />
          <input name="contact-evening" type="hidden" defaultValue={data.CONTACT_EVENING} />
          <input name="contact-email" type="hidden" defaultValue={data.CONTACT_EMAIL} />
          {data.ALLOW_PICKUP ? <input name="store-pickup" type="hidden" defaultValue={data.IN_STORE_PICKUP ? 1 : 0} /> : null}
          <input type="hidden" id="before-shipping-selector" value={data?.BEFORE_ADDRESS ? "below" : "above"} />
          <input name="before-address" type="hidden" defaultValue={data.BEFORE_ADDRESS} />
          <input name="before-city" type="hidden" defaultValue={data.BEFORE_CITY} />
          <input name="before-province" type="hidden" defaultValue={data.BEFORE_PROVINCE} />
          <input name="before-country" type="hidden" defaultValue={data.BEFORE_COUNTRY} />
          <input name="before-postal" type="hidden" defaultValue={data.BEFORE_POSTAL} />
          <input type="hidden" id="after-shipping-selector" value={data?.AFTER_ADDRESS ? "below" : "above"} />
          <input name="after-address" type="hidden" defaultValue={data.AFTER_ADDRESS} />
          <input name="after-city" type="hidden" defaultValue={data.AFTER_CITY} />
          <input name="after-province" type="hidden" defaultValue={data.AFTER_PROVINCE} />
          <input name="after-country" type="hidden" defaultValue={data.AFTER_COUNTRY} />
          <input name="after-postal" type="hidden" defaultValue={data.AFTER_POSTAL} />

          <div>
            Items per page:{" "}
            <select id="page-selector" style={{ width: "80px" }} value={String(items)} onChange={updateItems}>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
            </select>
          </div>

          <input type="hidden" name="action" value="update" />
          <div style={{ overflow: "scroll" }}>
            <div>
              <table className="table registry-listing" style={{ marginInline: "auto", fontSize: "large"}}>
                {/* className table-striped was removed for newLine highlights */}
                <thead>
                  <tr style={{ whiteSpace: "nowrap" }}>
                    <th id="heading-line">
                      LINE # <Sorter forOrder="line" dir={dir} order={order} setDir={updateDir} setOrder={setOrder} />
                    </th>
                    <th id="heading-title">
                      TITLE <Sorter forOrder="title" dir={dir} order={order} setDir={updateDir} setOrder={setOrder} />
                    </th>
                    <th>IMAGE</th>
                    <th id="heading-price">
                      PRICE <Sorter forOrder="price" dir={dir} order={order} setDir={updateDir} setOrder={setOrder} />
                    </th>
                    <th id="heading-wants">
                      WANTS <Sorter forOrder="wants" dir={dir} order={order} setDir={updateDir} setOrder={setOrder} />
                    </th>
                    <th id="heading-has">
                      HAS <Sorter forOrder="has" dir={dir} order={order} setDir={updateDir} setOrder={setOrder} />
                    </th>
                    <th id="heading-needs">
                      NEEDS <Sorter forOrder="needs" dir={dir} order={order} setDir={updateDir} setOrder={setOrder} />
                    </th>
                    <th>ADD TO CART</th>
                    <th>DELETE</th>
                  </tr>
                </thead>
                <tbody>
                  {data.ITEMS ? (
                    data.ITEMS.map((itemData) => (
                      <tr data-id={itemData.ID} key={itemData.ID}>
                        <td>
                          <input type="hidden" name="variant-ids" value={itemData.ID} />
                          <input size="2" aria-label="Position" type="text" name={`position-${itemData.ID}`} defaultValue={itemData.POSITION} onChange={(e) => checkUnsaved(e.target.name, itemData.POSITION, e.target.value)}/>
                        </td>
                        <td>
                          <b>{itemData.TITLE || itemData.HANDLE}</b>
                          <br />
                          {itemData.SKU}
                        </td>
                        <td>{itemData.IMAGE && <img src={String(itemData.IMAGE).replace(/(?=\.[^.]*$)/, "_200x100")} alt={itemData.TITLE} />}</td>
                        <td>{itemData.PRICE}</td>
                        <td>
                          <input
                            size="2"
                            type="text"
                            aria-label="Wants"
                            className="item-wants"
                            name={`wants-${itemData.ID}`}
                            onChange={(e) => checkUnsaved(e.target.name, itemData.WANTS, e.target.value)}
                            defaultValue={itemData.WANTS}
                            maxLength="5"
                          />
                        </td>
                        <td>
                          <input
                            size="2"
                            type="text"
                            aria-label="Has"
                            className="item-has"
                            name={`has-${itemData.ID}`}
                            onChange={(e) => checkUnsaved(e.target.name, itemData.HAS, e.target.value)}
                            defaultValue={itemData.HAS}
                            maxLength="5"
                          />
                        </td>
                        <td>{itemData.STILL_NEEDS}</td>
                        <td>
                          <Button size="large" onClick={() => attemptAddToCart(itemData.VARIANT_ID, itemData.TITLE,itemData.WANTS, itemData.HAS, itemData.STILL_NEEDS)}>ADD TO CART</Button>
                        </td>
                        <td>
                          <Button size="large" onClick={deleteVariant}>&times;</Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12">There are currently no items part of this registry.</td>
                    </tr>
                  )}

                  {pendingAdditions.map(({ productId, variantId, title, handle, sku, image, wants }, index) => (
                    <tr key={`pending-${variantId}-${index * 1}`} style={{ backgroundColor: "#dee" }}>
                      <td id="add-registry-line-number" aria-label="New Row" />
                      <td id="add-registry-product-title">
                        <b>{title || handle}</b>
                        <br />
                        {sku}
                        <input type="hidden" name="add-registry-product-id" value={productId} />
                        <input type="hidden" name="add-registry-variant-id" value={variantId} />
                      </td>
                      <td className="add-registry-product-image">{image && <img src={String(image).replace(/(?=\.[^.]*$)/, "_100x50")} alt={title} />}</td>
                      <td className="add-registry-variant-price" aria-label="Unknown Price">
                        &nbsp;
                      </td>
                      <td className="add-registry-variant-wants">
                        <input size="2" name="add-registry-variant-wants" aria-label="Wants" type="text" value={wants || 1} />
                      </td>
                      <td className="add-registry-variant-has">
                        <input size="2" name="add-registry-variant-has" aria-label="Has" type="text" defaultValue="0" />
                      </td>
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td>
                        <Button onClick={() => {setPendingAdditions([...pendingAdditions.slice(0, index), ...pendingAdditions.slice(index + 1)]); setUnsavedChanges([...unsavedChanges.filter(change => change.target !== variantId)]);}}>
                          &times;
                        </Button>
                      </td>
                    </tr>
                  ))}

                  {pendingAdditions.length > 0 ? (
                    <tr style={{ backgroundColor: "#dee" }}>
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td aria-label="empty" />
                      <td className="add-registry-variant-still-needs" style={{ minWidth: "55px" }}>
                        <button
                          type="submit"
                          name="action"
                          value="update"
                          className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
                        >
                          <span>Add</span>
                        </button>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
          <div id="registry-pagination" style={{ textAlign: "center" }}>
            <CreatePagination maxPages={data.MAX_PAGES} page={page} setPage={updatePage} />
          </div>

          <InlineStack gap="400" align="center">
            <Button onClick={addRegistryLineNumber}>&#x2795;&#xFE0F; Click here to add another item to this registry.</Button>
            {cartButton}
            {barcodeButton}
            <Button onClick={submit}>Save Changes</Button>
          </InlineStack>

          <input type="hidden" name="admin-description" defaultValue={data.ADMIN_DESCRIPTION} />
        </form>
      </Card>
    </Page>
    {showPageModal && (
      <Modal
        open={showPageModal}
        onClose={() => setShowPageModal(false)}
        title="Unsaved Changes"
        primaryAction={{
          content: "Leave",
          destructive: true,
          onAction: handleConfirmLeave,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => setShowPageModal(false),
          },
        ]}
      >
        <Modal.Section>
          <p>You have unsaved changes. Are you sure you want to leave?</p>
        </Modal.Section>
      </Modal>
    )}
    {showCartModal && (
        <Modal
      open={showCartModal}
      onClose={() => setShowCartModal(false)}
      title="Add to Cart"
      primaryAction={{ content: "Add", onAction: cartModalHandleSubmit }}
      secondaryActions={[{ content: "Cancel", onAction: () => setShowCartModal(false) }]}
    >
      <Modal.Section>
        <BlockStack align="center" vertical>
          <Text alignment="center" variant="headingMd">{cartModalVariantTitle}</Text>
          <Text alignment="center" variant="headingMd">Wants: {cartModalWants} Has: {cartModalHas} Needs: {cartModalNeeds}</Text>
          <Text alignment="center" variant="bodyMd">How many units would you like to add to cart?</Text>
          <InlineStack align="center">
          <Button onClick={cartModalDecreaseQuantity} size="large" fullWidth={false} disabled={cartModalQuantity <= 1}>−</Button>
          <TextField
                type="number"
                value={String(cartModalQuantity)}
                onChange={cartModalHandleQuantityChange}
                min={1}
                autoComplete="off"
                align="center"
                style={{ width: "80px", textAlign: "center", fontSize: "1.2rem" }}
              />
          <Button onClick={cartModalIncreaseQuantity} size="large" fullWidth={false}>+</Button>
          </InlineStack>
          {cartModalShowWarning && <Text tone="critical">You are adding more than you need ({cartModalNeeds}). Are you sure?</Text>}
        </BlockStack>
      </Modal.Section>
    </Modal>
    )}
    </>
  ) : null;
}

export default () => (
  <LegacyMessageContext>
    <ModifyList />
  </LegacyMessageContext>
);
